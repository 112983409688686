<template>
  <v-card>
    <v-form v-model="isValid" ref="form">
      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">お名前</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">{{ userNameLabel }}</label>
          </div>
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                required
                label="姓"
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataForm.userLastName"
                :rules="userNameRules.lastName"
                solo />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                required
                label="名"
                hide-details="auto"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataForm.userFirstName"
                :rules="userNameRules.firstName"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">フリガナ</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">{{ kanaLabel }}</label>
          </div>
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                required
                label="セイ"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataForm.kanaLastName"
                :rules="kanaRules.lastName"
                hide-details="auto"
                solo />
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                required
                label="メイ"
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataForm.kanaFirstName"
                :rules="kanaRules.firstName"
                hide-details="auto"
                solo />
            </v-col>
            </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">生年月日</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="ma-0 d-with-content">
          <div class="pl-6 d-flex d-single-row justify-start flex-wrap">
            <div class="d-with-ymd">
              <div class="flex-fill">
                <label class="font-label-sp">例：1970（半角）</label>
              </div>
              <v-text-field
                class="d-with-ymd-child mt-3"
                solo
                label=""
                hide-details="auto"
                hide-details-class="pa-0"
                @input="handlerDataFiledBirthday"
                @blur="handlerDataFiledBirthday"
                v-model="vModelDataForm.birthdayYear"
                :rules="[...birthdayYearRules, ...birthdayYMDRules]"
                maxlength="4"
                required>
                  <template v-slot:append-outer>
                    <span class="mr-2 ml-0">年</span>
                  </template>
              </v-text-field>
            </div>
            <div class="d-with-ymd">
              <div class="flex-fill s-item-second">
                <label class="font-label-sp">例：01（半角）</label>
              </div>
              <v-text-field
                required
                class="d-with-ymd-child mt-3"
                solo
                @input="handlerDataFiledBirthday"
                @blur="handlerDataFiledBirthday"
                v-model="vModelDataForm.birthdayMonth"
                :rules="[...birthdayMonthRules, ...birthdayYMDRules]"
                maxlength="2"
                label=""
                hide-details="auto">
                <template v-slot:append-outer>
                  <span class="mr-2 ml-0">月</span>
                </template>
              </v-text-field>
            </div>
            <div class="d-with-ymd">
              <div class="flex-fill s-item-second">
                <label class="font-label-sp">例：01（半角）</label>
              </div>
              <v-text-field
                class="d-with-ymd-child mt-3"
                solo
                @input="handlerDataFiledBirthday"
                @blur="handlerDataFiledBirthday"
                v-model="vModelDataForm.birthdayDay"
                :rules="[...birthdayDateRules, ...birthdayYMDRules]"
                maxlength="2"
                label=""
                hide-details="auto"
                required>
                <template v-slot:append-outer>
                  <span class="mr-2 ml-0">日</span>
                </template>
              </v-text-field>
            </div>
          </div>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">郵便番号（自宅）</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">{{ $t('common.postalCodeExample') }}</label>
          </div>
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="6" sm="6" md="4">
              <v-text-field
                required
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataForm.postalCode"
                :rules="postCodeRules"
                label=""
                minLength="7"
                maxLength="7"
                hide-details="auto"
                solo />
            </v-col>
            <v-col cols="4" sm="4" md="4" class="ma-0">
              <v-btn class="btn-action-postal-code pa-0 d-flex" title="住所検索" :disabled="!disabledPostalCode" @click="btnPostalCode">
                <v-icon x-large right class="mr-3 ml-1">mdi-chevron-right</v-icon>
                住所検索
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-150 bg-main-from d-flex justify-space-between align-center">
          <div class="d-block-pc">
            <div class="d-label-left ml-3">
              <span>ご住所（自宅）</span>
            </div>
            <v-chip class="cus-here-position d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </div>
        </div>
        <div class="lv1-label-duplicate-row">
          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2">
                <p class="ma-0 pa-0 mt-4">都道府県</p>
              </div>
            </div>
            <div class="d-none-single-row pt-3">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">都道府県</label>
                <label class="font-label-sp">{{ $t('common.prefectureExample') }}</label>
              </div>
              <v-col cols="12" sm="6" md="6" class="ml-3">
                <v-select
                  required
                  :items="prefectureItems"
                  item-text="name"
                  return-object
                  label="選択して下さい"
                  @change="handlerDataFiled"
                  v-model="vModelDataForm.prefecture"
                  :rules="prefectureRules"
                  hide-details="auto"
                  solo
                >
                  <template v-slot:append>
                    <v-img class="ma-0 pa-0"
                           src="@/assets/images/icon-arrow-down.svg"
                           contain
                    />
                  </template>
                </v-select>
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">市区郡</label>
                <p class="ma-0 pa-0 mt-4">市区郡</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">市区郡</label>
                <label class="font-label-sp">{{ $t('common.localityExample') }}</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  label=""
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataForm.cityOrCounty"
                  :rules="cityOrCountyRules"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2">
                <p class="ma-0 pa-0 mt-4">町名・番地</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">町名・番地</label>
                <label class="font-label-sp">{{ $t('common.streetAddressExample') }} </label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataForm.streetBunch"
                  label=""
                  :rules="streetBunchRules"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0 mr-6">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2 ">
                <p class="ma-0 pa-0 mt-4">ビル名・マンション名等</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">ビル名・マンション名等</label>
                <label class="font-label-sp">{{ $t('common.buildingNameExample') }}</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataForm.addressOther"
                  label=""
                  :rules="addressOtherRules"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-150 bg-main-from d-flex justify-space-between align-center">
          <div class="d-block-pc">
            <div class="d-box-title-pc">
              <p class="d-label-left ml-3">ご連絡先</p>
              <p class="ma-0 ml-4">* どちらか1つは</p>
              <p class="ma-0 ml-7"> 入力してください</p>
            </div>
            <v-chip class="cus-here-position d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </div>
        </div>
        <div class="lv1-label-duplicate-row">
          <v-row class="ma-0">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">自宅電話番号</label>
                <p class="ma-0 pa-0 mt-4">自宅電話番号</p>
              </div>
            </div>
            <div class="d-none-single-row mt-4">
              <div class="ml-6 mr-6">
                <label class="font-label-sp">{{ $t('common.telExample') }}</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  class="mr-6"
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataForm.homePhoneNumber"
                  label=""
                  :rules="[...homePhoneNumberRules, ...phoneNumberRules]"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>

          <v-row class="ma-0">
            <div class="md-max-100 bg-main-from justify-space-between align-center">
              <div class="d-main-multi mr-2">
                <p class="ma-0 pa-0 mt-4">携帯電話番号</p>
              </div>
            </div>
            <div class="d-none-single-row">
              <div class="ml-6 mr-2 d-box-sp-title align-end">
                <label class="font-weight-bold box-show-pc">携帯電話番号</label>
                <label class="font-label-sp">例：090-1234-5678 （半角）</label>
              </div>
              <v-col cols="12" sm="12" md="12" class="ml-3">
                <v-text-field
                  required
                  class="mr-6"
                  @input="handlerDataFiled"
                  @blur="handlerDataFiled"
                  v-model="vModelDataForm.mobileNumber"
                  label=""
                  :rules="[...mobileNumberRules, ...phoneNumberRules]"
                  hide-details="auto"
                  solo />
              </v-col>
            </div>
          </v-row>
        </div>
      </v-row>

      <v-row class="d-row-from ma-0">
        <div class="md-max-275 bg-main-from d-flex justify-space-between align-center">
          <span class="d-label-left ml-3">メールアドレス</span>
          <v-chip class="ma-2 d-label-required-bg" label text-color="white">
            {{ $t('common.labelRequired') }}
          </v-chip>
        </div>
        <div class="d-single-row">
          <div class="ml-6 mr-2">
            <label class="font-label-sp">例：tokyu_taro@tokyu-sharing.co.jp（半角）</label>
          </div>
          <v-row class="ml-3 mt-0 mr-3">
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                required
                @input="handlerDataFiled"
                @blur="handlerDataFiled"
                v-model="vModelDataForm.email"
                label=""
                :rules="emailRules"
                hide-details="auto"
                solo />
            </v-col>
          </v-row>
        </div>
      </v-row>
    </v-form>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import {
  dateCheckBirthdayValid,
  isEmailValid,
  isHomePhoneNumberValid, isKanaValid,
  isMobileNumberValid,
  isPostCodeValid
} from '@/utils/validators'
import C from '@/utils/constant'

export default {
  name: 'MainFormDocRequest',
  data () {
    return {
      isValid: true,
      userNameLabel: '例：東急　太郎（全角）',
      kanaLabel: '例：トウキュウ　タロウ（全角）',
      userNameRules: {
        lastName: [
          value => !!value || this.$t('rules.required', { title: this.$t('rules.data.lastName') })
        ],
        firstName: [
          value => !!value || this.$t('rules.required', { title: this.$t('rules.data.firstName') })
        ]
      },
      kanaRules: {
        lastName: [
          value => !!value || this.$t('rules.required', { title: this.$t('rules.data.kanaLastName') }),
          v => isKanaValid(v) || this.$t('rules.katakana', { title: this.$t('rules.data.katakana') })
        ],
        firstName: [
          value => !!value || this.$t('rules.required', { title: this.$t('rules.data.kanaFirstName') }),
          v => isKanaValid(v) || this.$t('rules.katakana', { title: this.$t('rules.data.katakana') })
        ]
      },
      emailRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.email') }),
        v => isEmailValid(v) || this.$t('rules.invalid', { title: this.$t('rules.data.email') })
      ],
      cityOrCountyRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.cityOrCounty') })
      ],
      streetBunchRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.streetBunch') })
      ],
      addressOtherRules: [
        true
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.addressOther') })
      ],
      homePhoneNumberRules: [
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.homePhoneNumber') }),
        v => ((!!v && isHomePhoneNumberValid(v)) || !v) || this.$t('rules.homeNumberValid')
      ],
      mobileNumberRules: [
        // v => !!v || this.$t('rules.required', { title: this.$t('rules.data.mobileNumber') }),
        v => ((!!v && isMobileNumberValid(v)) || !v) || this.$t('rules.mobileNumberValid')
      ],
      postCodeRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.postalCode') }),
        v => isPostCodeValid(v) || this.$t('rules.postCodeValid')
      ],
      birthdayYearRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.year') }),
        v => !isNaN(v) || this.$t('rules.invalid', { title: this.$t('rules.data.year') }),
        v => +v <= new Date().getFullYear() || this.$t('rules.invalid', { title: this.$t('rules.data.year') }),
        v => +v >= new Date().getFullYear() - 100 || this.$t('rules.invalid', { title: this.$t('rules.data.year') }) // Range -100y - year now
      ],
      birthdayMonthRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.month') }),
        v => !isNaN(v) || this.$t('rules.invalid', { title: this.$t('rules.data.month') }),
        v => +v <= 12 || this.$t('rules.invalid', { title: this.$t('rules.data.month') }),
        v => +v >= 1 || this.$t('rules.invalid', { title: this.$t('rules.data.month') })
      ],
      birthdayDateRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.date') }),
        v => !isNaN(v) || this.$t('rules.invalid', { title: this.$t('rules.data.date') }),
        v => +v <= 31 || this.$t('rules.invalid', { title: this.$t('rules.data.date') }),
        v => +v >= 1 || this.$t('rules.invalid', { title: this.$t('rules.data.date') })
      ],
      prefectureRules: [
        v => !!v || this.$t('rules.required', { title: this.$t('rules.data.prefecture') }),
        v => (v && Object.keys(v).length > 0) || this.$t('rules.required', { title: this.$t('rules.data.prefecture') })
      ],
      vModelDataForm: {
        userLastName: '',
        userFirstName: '',
        kanaLastName: '',
        kanaFirstName: '',
        birthdayYear: '',
        birthdayMonth: '',
        birthdayDay: '',
        postalCode: '',
        prefecture: {},
        cityOrCounty: '',
        streetBunch: '',
        addressOther: '',
        homePhoneNumber: '',
        mobileNumber: '',
        email: ''
      }
    }
  },
  model: {
    event: ['eventChangeDataDocReq']
  },
  props: {
    dataModel: {
      type: Object,
      required: false
    }
  },
  mounted () {
    this.initEnumPrefectureList()

    if (this.dataModel && Object.keys(this.dataModel).length) {
      this.vModelDataForm = this.dataModel
      this.$nextTick(() => {
        this.$refs.form.validate()
        this.eventDataPush()
      })
    }
  },
  methods: {
    initEnumPrefectureList () {
      this.$store.dispatch('fetchEnumPrefectureListDispatch', { apolloClient: this.$apollo, toast: this.$toast })
    },
    handlerDataFiled () {
      this.eventDataPush()
    },
    eventDataPush () {
      this.$nextTick(() => {
        this.$emit('eventChangeDataDocReq', {
          data: this.vModelDataForm,
          isValid: this.isValid
        })
      })
    },
    handlerDataFiledBirthday () {
      this.eventDataPush()
    },
    btnPostalCode () {
      const self = this
      if (self.vModelDataForm?.postalCode.length === 7) {
        self.$store.dispatch('fetchPostalCodeDispatch', {
          payload: self.vModelDataForm.postalCode,
          totast: self.$toast
        }).then(async data => {
          if (data) {
            if (data?.address1 && self.prefectureItems && Array.isArray(self.prefectureItems)) {
              const regex = new RegExp(`${data.address1}`)
              const find = self.prefectureItems.find(ele => regex.test(ele.name))
              if (find) {
                self.vModelDataForm.prefecture = find
              }
            }
            if (data?.address2) {
              self.vModelDataForm.cityOrCounty = data.address2
            }
            if (data?.address3) {
              self.vModelDataForm.streetBunch = data.address3
            }
          }
        })
      }
    }
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
      enumPrefectures: state => state.main.enumPrefectures
    }),
    constant () {
      return Object.assign({}, C)
    },
    prefectureItems () {
      return this.enumPrefectures
    },
    birthdayYMDRules () {
      return [
        dateCheckBirthdayValid(this.vModelDataForm.birthdayYear, this.vModelDataForm.birthdayMonth, this.vModelDataForm.birthdayDay) || this.$t('rules.invalid', { title: this.$t('rules.data.birthday') })
      ]
    },
    phoneNumberRules () {
      return [
        (`${this.vModelDataForm.homePhoneNumber}`.length > 0 || `${this.vModelDataForm.mobileNumber}`.length > 0) || this.$t('rules.invalid', { title: this.$t('rules.data.phoneNumber') })
      ]
    },
    disabledPostalCode () {
      return this.vModelDataForm.postalCode && !!this.vModelDataForm.postalCode.length
    }
  },
  watch: {
    // watch for change and then validate
    values: 'validate'
  }
}
</script>

<style lang="scss">
.d-row-from {
  border: 1px solid #CDD6DD;

  .d-with-ymd-child {
     .v-input__control .v-text-field__details {
       padding-left: 0;
       padding-right: 0;
     }
  }
}

</style>
