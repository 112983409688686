<template>
  <div class="document-request-mfr-show-step2">
    <v-card class="mt-30">
      <v-card-text class="ma-0 pa-0">
        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">お名前</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ userNameFull }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">フリガナ</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ kanaNameFull }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">生年月日</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ birthday }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">郵便番号（自宅）</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.postalCode || '' }}</p>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">ご住所（自宅）</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" cols="12">
            <v-row class="bd-from-bottom">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">都道府県</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ prefecture || '' }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">市区郡</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.cityOrCounty || '' }}</p>
              </v-col>
            </v-row>

            <v-row class="bd-from-bottom">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">町名・番地</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.streetBunch || '' }}</p>
              </v-col>
            </v-row>

            <v-row>
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">ビル名・マンション名等</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.addressOther || '' }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col md="3" sm="2" cols="12" class="bg-main-from d-flex justify-space-between align-center pt-0 pb-0">
            <span class="d-label-left">ご連絡先</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" :class="['']">
            <v-row :class="[{'bd-from-bottom': mainFormDocRequest.mobileNumber}]" v-if="mainFormDocRequest.homePhoneNumber">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">自宅電話番号</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.homePhoneNumber || '' }}</p>
              </v-col>
            </v-row>

            <v-row v-if="mainFormDocRequest.mobileNumber">
              <v-col md="2" sm="2" cols="4" class="bd-from-right text-end">
                <span class="d-label-left">携帯電話番号</span>
              </v-col>
              <v-col md="10" sm="10" cols="8" class="d-flex justify-space-between align-center">
                <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.mobileNumber || '' }}</p>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="d-row-from-border ma-0">
          <v-col cols="12" md="3" sm="2" class="bg-main-from d-flex justify-space-between align-center">
            <span class="d-label-left">メールアドレス</span>
            <v-chip class="ma-2 d-label-required-bg" label text-color="white">
              {{ $t('common.labelRequired') }}
            </v-chip>
          </v-col>
          <v-col md="9" sm="10" class="d-flex justify-space-between align-center">
            <p class="ma-0 d-flex flex-wrap">{{ mainFormDocRequest.email || '' }}</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import C from '@/utils/constant'
import { mapState } from 'vuex'
import createWebRequest from '@/mixins/createWebRequest'

export default {
  name: 'MainFormRequestShow',
  mixins: [
    createWebRequest
  ],
  data () {
    return {}
  },
  props: {
    type: {
      type: String,
      required: true
    }
  },
  mounted () {
  },
  computed: {
    ...mapState({
      // Format [stageName] => state.[modulesName].[stageName],
    }),
    constant () {
      return Object.assign({}, C)
    },
    userNameFull () {
      return `${this.mainFormDocRequest?.userLastName} ${this.mainFormDocRequest?.userFirstName}`
    },
    kanaNameFull () {
      return `${this.mainFormDocRequest?.kanaLastName} ${this.mainFormDocRequest?.kanaFirstName}`
    },
    birthday () {
      return this.birthdayFull(this.mainFormDocRequest?.birthdayYear, this.mainFormDocRequest?.birthdayMonth, this.mainFormDocRequest?.birthdayDay)
    },
    prefecture () {
      return `${this.mainFormDocRequest?.prefecture?.name}`
    }
  },
  created () {
    this.typeApply = this.type
  }
}
</script>

<style lang="scss">
.mt-30 {
  margin-top: 30px;
}
</style>
